import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';

import { LdsPageProps } from './models';

const LdsPage: FC<{ data: LdsPageProps }> = ({ data: { lds, ldsSeoPage } }) => {
  const { body } = lds;
  const [bodyParsed, titleParsed, contentParsed] =
    body.match(/<p><b>(.*?)<\/b><\/p>(<p.*?)$/) || [];

  return (
    <Layout>
      {ldsSeoPage?.seoMetaTitle ? (
        <>
          <Seo
            {...{
              title: ldsSeoPage.seoMetaTitle,
              description: ldsSeoPage.seoMetaDescription,
              keywords: ldsSeoPage.seoMetaKeywords,
            }}
          />
          <PageSchema
            type="WebPage"
            name={ldsSeoPage.seoMetaTitle}
            data={{
              metaTitle: ldsSeoPage.seoMetaTitle,
              metaDescription: ldsSeoPage.seoMetaDescription,
              metaKeywords: ldsSeoPage.seoMetaKeywords,
            }}
          />
        </>
      ) : null}
      <div className="content container politics">
        <div className="content__title">
          <h1 className="title" dangerouslySetInnerHTML={{ __html: titleParsed }} />
        </div>
        {bodyParsed ? (
          <div className="content__body" dangerouslySetInnerHTML={{ __html: contentParsed }} />
        ) : null}
        {body ? <div className="content__body" dangerouslySetInnerHTML={{ __html: body }} /> : null}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($ldsContentType: String) {
    ldsSeoPage(ldsContentType: { eq: $ldsContentType }) {
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
    }
    lds(contentType: { eq: $ldsContentType }) {
      body
      contentType
      fields {
        slug
      }
    }
  }
`;

export default LdsPage;
